<template>
  <span>
    <transition name="fade">
      <settings-warning v-if="!hasChange" style="animation-duration: 0.5s" />
    </transition>
    <div class="options">
      <hcc-card
        :title="$t('configuration.options.title')"
        color="gray"
        class="card-body border"
      >
        <config-card
          :title="$t('configuration.options.custom-images.title')"
          :key="`${this.campaign.id}1`"
          :text="$t('configuration.options.custom-images.description')"
          v-model="optionModal.customImages"
          name="images"
        />
        <config-card
          :title="$t('configuration.options.custom-documents.title')"
          :key="`${this.campaign.id}2`"
          :text="$t('configuration.options.custom-documents.description')"
          v-model="optionModal.customDocuments"
          name="documents"
        />
        <config-card
          :title="$t('configuration.options.custom-audio.title')"
          :key="`${this.campaign.id}3`"
          :text="$t('configuration.options.custom-audio.description')"
          v-model="optionModal.customAudio"
          name="audio"
        />
        <config-card
          :title="$t('configuration.options.favorites-enabled.title')"
          :key="`${this.campaign.id}10`"
          :text="$t('configuration.options.favorites-enabled.description')"
          v-model="optionModal.favoritesEnabled"
          name="favorite"
        />
        <config-card
          :title="$t('configuration.options.block-users.title')"
          :key="`${this.campaign.id}11`"
          :text="$t('configuration.options.block-users.description')"
          v-model="optionModal.blockUsersEnabled"
          name="blocked"
        />
        <config-card
          :title="$t('configuration.options.transfer-conversations.title')"
          :key="`${this.campaign.id}4`"
          :text="$t('configuration.options.transfer-conversations.description')"
          v-model="optionModal.transferConversations"
          name="transConv"
        />
        <config-card
          :title="$t('configuration.options.transfer-between-campaigns.title')"
          :key="`${this.campaign.id}9`"
          :text="
            $t('configuration.options.transfer-between-campaigns.description')
          "
          v-model="optionModal.transferBetweenCampaigns"
          name="transCamp"
        />
        <hcc-card class="custom-card">
          <div class="flex-container align-middle align-justify item-card">
            <div class="column-left">
              <p class="card__title">
                {{
                  $t(
                    "configuration.options.max-open-conversations-by-agent.title"
                  )
                }}
              </p>
              <hcc-input
                type="number"
                name="maxConversations"
                min="0"
                placeholder="0"
                v-model.number="optionModal.maxOpenConversationsByAgent"
                class="input"
              />
              <p class="card__description">
                {{
                  $t(
                    "configuration.options.max-open-conversations-by-agent.description"
                  )
                }}
              </p>
            </div>
          </div>
        </hcc-card>
      </hcc-card>
      <hcc-card title="General" color="gray" class="card-body border">
        <config-card
          :title="$t('configuration.options.hide-username.title')"
          :key="`${this.campaign.id}8`"
          :text="$t('configuration.options.hide-username.description')"
          v-model="optionModal.hideUsername"
          name="number"
        />
        <hcc-card class="custom-card">
          <div class="flex-container align-middle align-justify item-card">
            <div class="column-left">
              <p class="card__title">
                {{ $t("configuration.options.chat-history-days.title") }}
              </p>
              <hcc-input
                type="number"
                size="sm"
                min="0"
                placeholder="0"
                v-model.number="optionModal.chatHistoryDays"
                name="history"
                class="input"
              />
              <p class="card__description">
                {{ $t("configuration.options.chat-history-days.description") }}
              </p>
            </div>
          </div>
        </hcc-card>
        <hcc-card class="custom-card">
          <div class="flex-container align-middle align-justify item-card">
            <div class="column-left">
              <p class="card__title">
                {{
                  $t(
                    "configuration.options.max-conversations-before-assign.title"
                  )
                }}
              </p>
              <hcc-input
                type="number"
                size="sm"
                min="0"
                placeholder="0"
                v-model.number="optionModal.maxConversationsBeforeAssign"
                name="queuedConv"
                class="input"
              />
              <p class="card__description">
                {{
                  $t(
                    "configuration.options.max-conversations-before-assign.description"
                  )
                }}
              </p>
            </div>
          </div>
        </hcc-card>
        <config-card
          :title="$t('configuration.options.close-conversations-when-logging-out.title')"
          :text="$t('configuration.options.close-conversations-when-logging-out.description')"
          name="closeLogOut"
          :key="`${this.campaign.id}5`"
          v-model="optionModal.closeConversationsWhenLoggingOut"
        />
        <config-card
          :title="$t('configuration.options.reassign-conversations-when-logging-out.title')"
          :text="$t('configuration.options.reassign-conversations-when-logging-out.description')"
          name="reasignarLogOut"
          :key="`${this.campaign.id}6`"
          v-model="optionModal.reassignConversationsWhenLoggingOut"
        />
        <config-card
          :title="$t('configuration.options.receive-conversations.title')"
          :text="$t('configuration.options.receive-conversations.description')"
          :key="`${this.campaign.id}13`"
          v-model="optionModal.receiveConversationOutOfTime"
          name="receiveConversationOutOfTime"
        />
        <config-card
          :title="$t('configuration.options.bot-ivr-enabled.title')"
          v-if="role"
          :text="$t('configuration.options.bot-ivr-enabled.description')"
          :key="`${this.campaign.id}7`"
          v-model="optionModal.botIvrEnabled"
          name="ivr"
        />
        <hcc-card class="custom-card" v-if="optionModal.botIvrEnabled && role">
          <div class="flex-container align-middle align-justify item-card">
            <div class="column-left">
              <p class="card__title">TTLs</p>
              <hcc-input
                type="number"
                name="botIVR"
                min="0"
                placeholder="0"
                v-model.number="optionModal.botConversationTtl"
                class="input"
              />
              <p class="card__description">
                {{
                  $t("configuration.options.bot-conversation-ttl.description")
                }}
              </p>
            </div>
          </div>
        </hcc-card>
        <config-card
          :title="$t('configuration.messages.close-out-of-service')"
          :text="$t('configuration.messages.close-out-of-service-description')"
          :key="`${this.campaign.id}10`"
          :disabled="hasOutOfServiceMesg"
          v-model="optionModal.closeConversationOutOfService"
          name="outOfService"
        />
        <hcc-card class="custom-card" v-if="showSelectCategory">
          <div class="flex-container align-middle align-justify item-card">
            <div class="column-left">
               <hcc-select
                :label="$t('configuration.messages.category.title')"
                customClass="card-select"
                track-by="title"
                option-label="title"
                :options="categories"
                v-model="optionModal.outOfServiceCategoryId"
              />
              <p class="card__description">
                {{ $t("configuration.messages.categoryOutOfService") }}
              </p>
            </div>
          </div>
        </hcc-card>
        <hcc-button
          type="submit"
          size="md"
          class="submit"
          :disabled="hasChange || isSaving"
          @click="save()"
          >{{ $t("common.save") }}
        </hcc-button>
      </hcc-card>
    </div>
    <transition name="fade">
      <hcc-confirmation :confirmFunction="handleConfirm" />
    </transition>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';
import jwtDecode from 'jwt-decode';
import EventBus from '@/eventBus';
import ErrorHandler from '@/utils/error.handler';
import { UPDATE_CAMPAIGN, UPDATE_CAMPAIGN_DONE, UPDATE_CAMPAIGN_ERROR } from '@/eventTypes';
import HccConfirmation from '@/components/shared/HccConfirmation/index.vue';
import SettingsWarning from './SettingsWarning.vue';

export default {
  props: {
    campaign: {
      required: true,
    },
  },
  components: {
    HccCard: () => import('@/components/shared/HccCard/index.vue'),
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    HccButton: () => import('@/components/shared/HccButton/index.vue'),
    ConfigCard: () => import('@/components/settings/ConfigCard.vue'),
    HccSelect: () => import('@/components/shared/HccSelect/index.vue'),
    HccConfirmation,
    SettingsWarning,
  },
  data() {
    return {
      options: null,
      isSaving: false,
      optionModal: {
        customImages: null,
        customDocuments: null,
        customAudio: null,
        transferConversations: null,
        transferBetweenCampaigns: null,
        hideUsername: null,
        botIvrEnabled: null,
        chatHistoryDays: null,
        botConversationTtl: null,
        maxConversationsBeforeAssign: null,
        maxOpenConversationsByAgent: null,
        closeConversationsWhenLoggingOut: null,
        reassignConversationsWhenLoggingOut: null,
        receiveConversationOutOfTime: null,
        favoritesEnabled: false,
        blockUsersEnabled: false,
        closeConversationOutOfService: false,
        outOfServiceCategoryId: {},
        unattendedWarningCloseCategoryId: null,
      },
      originalData: '',
      categories: [],
      messages: [],
    };
  },
  computed: {
    ...mapGetters({
      accessToken: 'dashboard/getAccessToken',
    }),
    decodedData() {
      let decoded;
      try {
        decoded = jwtDecode(this.accessToken);
      } catch (e) {
        decoded = {};
      }
      return decoded.role ? decoded.role : 'invalid';
    },
    role() {
      return this.decodedData === 'owner';
    },
    hasChange() {
      const change = this.updatedData === this.originalData;

      this.$emit('changed', !change);

      return change;
    },
    updatedData() {
      return Object.values(this.optionModal).join();
    },
    showSelectCategory() {
      return this.optionModal.closeConversationOutOfService;
    },
    outOfServiceMesg() {
      if (this.messages) {
        return this.messages.find(({ name }) => name === 'messageOutOfService');
      }
      return false;
    },
    hasOutOfServiceMesg() {
      return this.outOfServiceMesg?.value === '';
    },
    validCategory() {
      return this.optionModal.closeConversationOutOfService
        ? (this.optionModal.closeConversationOutOfService
        && !!this.optionModal.outOfServiceCategoryId?.id) : true;
    },
  },
  watch: {
    'campaign.config': function setConfig(newConfig) {
      this.options = newConfig;
      this.updateDataList();
    },
    'campaign.categories': function setCategories(newCategories) {
      if (newCategories) {
        this.categories = newCategories;
      }
    },
    'optionModal.transferConversations': function transfer(value) {
      if (!value) {
        this.$set(this.optionModal, 'transferBetweenCampaigns', value);
      }
    },
    'optionModal.transferBetweenCampaigns': function transfer(value) {
      if (value) {
        this.$set(this.optionModal, 'transferConversations', value);
      }
    },
    'campaign.messages': function setMssages(newMessages) {
      this.messages = newMessages;
    },
    'optionModal.closeConversationsWhenLoggingOut': function actionMsg(value) {
      if (value) {
        this.$set(this.optionModal, 'reassignConversationsWhenLoggingOut', !value);
      }
    },
    'optionModal.reassignConversationsWhenLoggingOut': function actionMsg(value) {
      if (value) {
        this.$set(this.optionModal, 'closeConversationsWhenLoggingOut', !value);
      }
    },
  },
  created() {
    EventBus.$on(UPDATE_CAMPAIGN_DONE, (tab) => {
      this.isSaving = false;
      if (tab === 'Options') {
        this.$toasted.global.success(this.$t('configuration.options.updated'));
      }
    });
    EventBus.$on(UPDATE_CAMPAIGN_ERROR, (err) => {
      ErrorHandler.logErrors(err);
      this.isSaving = false;
    });
  },
  mounted() {
    this.options = this.campaign.config;
    this.categories = this.campaign.categories;
    this.updateDataList();
  },
  destroyed() {
    EventBus.$off(UPDATE_CAMPAIGN_ERROR);
    EventBus.$off(UPDATE_CAMPAIGN_DONE);
  },
  methods: {
    updateDataList() {
      const { outOfServiceCategoryId } = this.options || {};
      const config = this.options || {};

      Object.entries(config).forEach((item) => {
        const [key, val] = item;
        if (key !== '__typename') {
          this.optionModal[key] = val;
        }
      });

      if (this.categories) {
        this.optionModal.outOfServiceCategoryId = this.categories
          .find(({ id }) => id === outOfServiceCategoryId) || {};
      } else {
        this.optionModal.outOfServiceCategoryId = {};
      }
      this.originalData = Object.values(this.optionModal).join();
    },
    save() {
      this.isSaving = true;
      this.checkEmptyValue();

      if (!this.validCategory) {
        this.$toasted.global.error(this.$t('configuration.options.error-empty-category'));
        this.isSaving = false;
        return;
      }
      this.optionModal.outOfServiceCategoryId = this.optionModal.outOfServiceCategoryId.id;
      const data = {
        id: this.campaign.id,
        config: this.optionModal,
      };

      EventBus.$emit(UPDATE_CAMPAIGN, { data, tab: 'Options' });
    },
    checkEmptyValue() {
      this.optionModal.botConversationTtl = this.optionModal.botIvrEnabled ? this
        .optionModal.botConversationTtl : 0;
      this.optionModal.chatHistoryDays = this.optionModal.chatHistoryDays || 0;
      this.optionModal.maxConversationsBeforeAssign = this.optionModal
        .maxConversationsBeforeAssign || 0;
      this.optionModal.maxOpenConversationsByAgent = this.optionModal
        .maxOpenConversationsByAgent || 0;
    },
    validNumber(data, fallback) {
      return data != null ? data : fallback;
    },
    handleConfirm() {
      this.updateDataList();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/settings/_options-tab.scss";
</style>
